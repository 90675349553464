import React from 'react';
import linkdin_logo from '../../../../../assets/linkdin-logo.png';

export const linkedinIcon = () => {
  return <img src={linkdin_logo} />
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16">
      <path d="M13.938.188H1.184C.6.188.125.667.125 1.26v12.73c0 .591.475 1.072 1.06 1.072h12.753A1.07 1.07 0 0 0 15 13.99V1.26A1.07 1.07 0 0 0 13.938.187ZM4.62 12.938H2.416v-7.1h2.208v7.1h-.003Zm-1.102-8.07a1.279 1.279 0 1 1 .001-2.558 1.279 1.279 0 0 1 0 2.558Zm9.367 8.069H10.68V9.483c0-.823-.016-1.882-1.145-1.882-1.15 0-1.325.896-1.325 1.823v3.512H6.005V5.84H8.12v.97h.03c.296-.559 1.016-1.146 2.089-1.146 2.23 0 2.646 1.47 2.646 3.383v3.892Z" />
    </svg>
  );
};
