import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { LinkedLogo } from '../../../../components';

import Field from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';
import visa_logo from '../../../../assets/visa-logo.png';
import master_logo from '../../../../assets/master-logo.png';
import amex_logo from '../../../../assets/amex-logo.png';
import bitcoin_logo from '../../../../assets/Bitcoin.png';

function extractLabelAndLink(markdownText) {
  const regex = /^(.+?)\[(.+)\]$/;
  const match = markdownText.match(regex);

  if (match) {
    return {
      label: match[1].trim(),
      link: match[2],
    };
  } else {
    return null; // or throw an error if no match is found
  }
}

function snakeToTitleCase(snakeStr) {
  const words = snakeStr.split('_');

  const titleCaseStr = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  return snakeStr === 'reward_stokens' ? 'Reward & Stokens' : titleCaseStr;
}

// The number of columns (numberOfColumns) affects styling

const GRID_CONFIG = [
  { contentCss: css.contentCol1, gridCss: css.gridCol1 },
  { contentCss: css.contentCol2, gridCss: css.gridCol2 },
  { contentCss: css.contentCol3, gridCss: css.gridCol3 },
  { contentCss: css.contentCol4, gridCss: css.gridCol4 },
];
const MAX_MOBILE_SCREEN_WIDTH = 1024;

const getIndex = numberOfColumns => numberOfColumns - 1;

const getContentCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.contentCss : GRID_CONFIG[0].contentCss;
};

const getGridCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.gridCss : GRID_CONFIG[0].gridCss;
};

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const {
    sectionId,
    className,
    rootClassName,
    numberOfColumns,
    socialMediaLinks,
    slogan,
    appearance,
    copyright,
    blocks,
    options,
    linkLogoToExternalSite,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const linksWithBlockId = socialMediaLinks?.map(sml => {
    return {
      ...sml,
      blockId: sml.link.platform,
    };
  });

  const showSocialMediaLinks = socialMediaLinks?.length > 0;
  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;
  const logoLayout = isMobileLayout ? 'mobile' : 'desktop';

  function getFooterLinksFromBlock(type) {
    const categoryBlock = blocks.find(block => block.blockId === type);
    const categories = categoryBlock.text.content.split('\n');
    const categoriesArray = categories.map(category => {
      const object = extractLabelAndLink(category);
      return object;
    });
    return categoriesArray;
  }

  const Links = ({ category, linksArray = [] }) => {
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
      // Simulate an async call
      if (linksArray.length > 0) {
        setLoading(false); // Set loading to false once linksArray is populated
      }
    }, [linksArray]);

    return (
      <div className={css.footerBlock}>
        <h4>{category || 'Category'}</h4>
        {loading ? ( // Check if loading
          <p>Loading...</p>
        ) : linksArray.length === 0 ? (
          <p>No links available</p>
        ) : (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {linksArray.map((link, index) => {
              const linkHref = link?.link || '#'; // Default to '#' if link is undefined or null
              const linkLabel = link?.label || 'Home'; // Default to 'Home Link' if label is undefined or null

              return (
                <li key={`${linkLabel}-${index}`} style={{ marginBottom: '8px' }}>
                  <a href={linkHref} style={{ color: '#fff', textDecoration: 'none' }}>
                    {linkLabel}
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  };

  const listingLinkBlocks = blocks?.filter(
    block => block.blockId === 'selling' || block.blockId === 'buying'
  );

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={className || css.root}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.footer}>
        <div className={classNames(css.content)}>
          <div>
            <LinkedLogo
              rootClassName={css.logoLink}
              logoClassName={css.logoWrapper}
              logoImageClassName={css.logoImage}
              linkToExternalSite={linkLogoToExternalSite}
              layout={logoLayout}
            />
            <div className={css.sloganMobile}>
              <Field data={slogan} className={css.slogan} />
            </div>
            <div className={css.detailsInfo}>
              <div className={css.sloganDesktop}>
                <Field data={slogan} className={css.slogan} />
              </div>
              <Field data={copyright} className={css.copyright} />
              <div className={css.mobileView}>
                {blocks?.length > 0 &&
                  blocks.map(block => {
                    const blockTitle = snakeToTitleCase(block.blockId);
                    return (
                      <span key={blockTitle}>
                        <Links
                          category={blockTitle}
                          linksArray={getFooterLinksFromBlock(block.blockId)}
                        />
                      </span>
                    );
                  })}
              </div>
              {showSocialMediaLinks ? (
                <div className={css.icons}>
                  <BlockBuilder blocks={linksWithBlockId} sectionId={sectionId} options={options} />
                </div>
              ) : null}
              <div className={css.cardLogoContainer}>
                <span className={css.visaLogo}>
                  <img src={visa_logo} />
                </span>
                <span className={css.masterLogo}>
                  <img src={master_logo} />
                </span>
                <span className={css.amexLogo}>
                  <img src={amex_logo} />
                </span>
                <span className={css.bitcoinLogo}>
                  <img src={bitcoin_logo} />
                </span>
              </div>
            </div>
          </div>
          <div className={css.desktopView}>
            {blocks?.length > 0 &&
              blocks.map(block => {
                const blockTitle = snakeToTitleCase(block.blockId);
                return (
                  <span key={blockTitle}>
                    <Links
                      category={blockTitle}
                      linksArray={getFooterLinksFromBlock(block.blockId)}
                    />
                  </span>
                );
              })}
          </div>
          {/* <div className={classNames(css.grid, getGridCss(numberOfColumns))}>
            <BlockBuilder blocks={blocks} sectionId={sectionId} options={options} />
          </div> */}
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionFooter;
