import React from "react";
import css from './SectionBuilder.module.css';
import { AvatarMedium, NamedLink } from "../../../components";
import { FormattedMessage } from "react-intl";
import stokenIcon from '../../../assets/NewStokenIcon.svg'
import StokenIcon from "../../../components/StokenIcon/StokenIcon";

const UserCard = (props) => {
  const {user, index, isTopEarnerCard, history} = props;
  const {profileImage, totalListings} = user || {};
  const { userName, rewardsWallet = 0 } = user?.attributes?.profile?.publicData || {};
  const { firstName, displayName } = user?.attributes?.profile || '';

  return (
    <NamedLink name='ProfilePage' params={{id: user?.id?.uuid}} className={css.featuredSellerCard}>
      <div className={css.count}>{index + 1}</div>
      {profileImage ? (
        <img src={profileImage} className={css.userProfileImage} />
      ) : (
        <AvatarMedium user={user} className={css.avatar} />
      )}
      <p className={css.featuredCardTitle}>
        <FormattedMessage
          id="ListingCard.author"
          values={{ authorName: displayName }}
        />
      </p>
      <div className={css.userName}>
        {`@${userName || firstName}`}
      </div>
      {isTopEarnerCard ? (
        <div className={css.rewardPointWrapper}>
          <span className={css.stokenIcon}>
            <img src={'https://sharetribe-assets.imgix.net/65a4962c-9527-45f9-9268-8c781a47853d/raw/53/a0b4712554df59768d4fabdaffe0c244e2f1b9?auto=format&fit=clip&h=1200&w=1200&s=7132ff245934ffdd27c633caffcf4b17'}/>
          </span>
          {/* <StokenIcon /> */}
          <div className={css.productCount}>
            {rewardsWallet}
          </div>
        </div>
      ) : (
        <div className={css.productCount}>
          {`${totalListings}`} {totalListings < 2 ? 'Listing' : 'Listings'}
        </div>
      )}
    </NamedLink>
  )
}

export default UserCard