import React from 'react';
import { ListingCard } from '../../../components';
import Slider from 'react-slick';
import css from './SectionBuilder.module.css';


const TrendingListing = (props) => {
  const { trendingListings, cardRenderSizes, dispatch, setActiveListing, isMapVariant } = props;
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    // variableWidth: true,
    arrows: true,
    swipeToSlide: false,
    draggable: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1840,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1640,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          swipeToSlide: true,
          draggable: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings} className={css.listingsDesktopView}>
        {trendingListings?.map(list => {
          return (
            <div key={list.id.uuid} style={{ width: 250 }}>
              <ListingCard
                className={css.listingCard}
                key={list.id.uuid}
                listing={list}
                renderSizes={cardRenderSizes(isMapVariant)}
                setActiveListing={() => dispatch(setActiveListing(list.id))}
              />
            </div>
          );
        })}
      </Slider>
      <div className={css.listingsMobileView}>
        {trendingListings?.map(list => {
          return (
            <div key={list.id.uuid}>
              <ListingCard
                className={css.listingCard}
                key={list.id.uuid}
                listing={list}
                renderSizes={cardRenderSizes(isMapVariant)}
                setActiveListing={() => dispatch(setActiveListing(list.id))}
              />
            </div>
          );
        })}
      </div>
    </>
  )
}

export default TrendingListing