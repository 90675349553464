import React, { useState, useEffect } from 'react';
import css from './SectionBuilder.module.css';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const SeenOnSocialMediaListings = props => {
  const { seenOnSocialMediaSection = {} } = props;
  const [loading, setLoading] = useState(true); // Add loading state
  const { blocks = [] } = seenOnSocialMediaSection;

  useEffect(() => {
    // Simulate an async call
    if (blocks.length > 0) {
      setLoading(false); // Set loading to false once blocks are available
    }
  }, [blocks]);

  return (
    <>
      {!loading && blocks.length > 0 && (
        <div className={classNames(css.sectionTrending, css.socialMediaBlocks)}>
          <h2 className={css.sectionTitle}>
            <FormattedMessage id="SeenOnSocialMedia.title" defaultMessage="Seen on Social Media" />
          </h2>
          <p className={css.subHeading}>
            <FormattedMessage
              id="SeenOnSocialMedia.subTitle"
              defaultMessage="Check out these popular posts!"
            />
          </p>
          <div className={css.imagesWrapper}>
            {blocks.map((block, index) => {
              const { text = {}, media = {} } = block;
              const image = media?.image?.attributes?.variants['square400'] || {};

              return (
                <div
                  key={`${index}-${image.name || 'default-image'}`}
                  className={css.socialMediaCard}
                >
                  <img src={image.url} alt={image.name || 'Image'} />
                  <span className={css.caption}>{text?.content || 'No caption available'}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default SeenOnSocialMediaListings;
