import React, { useEffect, useState } from 'react';
import css from './Modal.module.css';
import { useSelector } from 'react-redux';
import avatar from '../../assets/ProfilePic.svg';
import { RWebShare } from 'react-web-share';

const ReffralModal = ({ isOpen, onClose }) => {
  const state = useSelector(state => state);
  const { currentUser } = state.user;
  const [userID, setUserID] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.id && currentUser.id.uuid) {
      setUserID(currentUser.id.uuid);
    }
    if (typeof window !== 'undefined') {
      setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    }
  }, [currentUser]);
  const baseURL = typeof window !== 'undefined' ? window.location.origin : '';
  const referralLink = `${baseURL}/signup?referid=${userID}`;

  if (!isOpen) return null;
  const copyToClipboard = () => {
    if (typeof navigator !== 'undefined' && navigator.clipboard) {
      navigator.clipboard.writeText(referralLink).then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      });
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check out this awesome service!',
          text: 'Join using my referral link and earn rewards!',
          url: referralLink,
        });
      } catch (err) {
        console.error('Error sharing', err);
      }
    } else {
      console.log('Sharing not supported on this browser.');
    }
  };

  return (
    <div className={css.modalOverlay}>
      <div className={css.modalContent}>
        <div className={css.modalHeader}>
          <img src={avatar} alt="Avatar" className={css.avatar} />
          <h2>Refer now & earn up to £100</h2>
          <p>Send a referral link to your friends via WhatsApp / Facebook / Email</p>
        </div>

        <div className={css.referralCodeSection}>
          <p>Referral Link</p>
          <input type="text" value={referralLink} readOnly className={css.inputField} />
        </div>

        <div className={css.buttonsContainer}>
          {isMobile ? (
            <button onClick={handleShare} className={css.shareButton}>
              Share
            </button>
          ) : (
            <RWebShare
              data={{
                text:
                  'Join me on Stoado, the best platform to buy and sell unique items. Sign up now and start your journey!',
                url: referralLink,
                title: 'Join Stoado - Buy & Sell Marketplace',
              }}
              onClick={() => navigator.clipboard.writeText(referralLink)}
            >
              <button className={css.shareButton}>Share</button>
            </RWebShare>
          )}
          <button onClick={copyToClipboard} className={css.copyButton}>
            Copy to Clipboard
          </button>
        </div>

        <button onClick={onClose} className={css.closeButton}>
          Close
        </button>
        {copySuccess && <div className={css.copySuccessBanner}>✔️ Link copied to clipboard</div>}
      </div>
    </div>
  );
};

export default ReffralModal;
