import React from "react";
import css from './SectionBuilder.module.css';
import Slider from 'react-slick';
import { FormattedMessage } from "react-intl";

const PopularCategories = (props) => {
  const {popularCategories, history} = props;

  const settings = {
    dots: false,
    infinite: popularCategories.length > 5 ? true : false,
    speed: 500,
    slidesToShow: 5.4,
    slidesToScroll: 2,
    // variableWidth: true,
    arrows: true,
    swipeToSlide: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1840,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1640,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          swipeToSlide: true,
          draggable: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  return (
    <>
      <Slider {...settings} className={css.listingsDesktopView}>
        {popularCategories?.map((type, id) => {
          return (
            <div key={id} className={css.categoryCard}>
              <img src={type.icon} className={css.cardImg} />
              <div className={css.cardInfo}>
                <p className={css.cardLabel}> {type.label}</p>
                <button
                  onClick={() => {
                    history.push(`/s${type.search}`);
                  }}
                  className={css.seeAll}
                >
                  <FormattedMessage id="LandingPage.seeAll" />
                </button>
              </div>
            </div>
          );
        })}
      </Slider>
      <div className={css.listingsMobileView}>
        {popularCategories?.map((type, id) => {
          return (
            <div key={id} className={css.categoryCard}>
              <img src={type.icon} className={css.cardImg} />
              <div className={css.cardInfo}>
                <p className={css.cardLabel}> {type.label}</p>
                <button
                  onClick={() => {
                    history.push(`/s${type.search}`);
                  }}
                  className={css.seeAll}
                >
                  <FormattedMessage id="LandingPage.seeAll" />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  )
}

export default PopularCategories