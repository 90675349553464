import Slider from 'react-slick';
import css from './SectionBuilder.module.css';
import { ListingCard } from '../../../components';
import { useDispatch } from 'react-redux';

const GetItBeforeGoneListings = props => {
  const dispatch = useDispatch();
  const { listings, renderSizes, setActiveListing } = props;

  const settings = {
    dots: false,
    infinite: listings.length > 5 ? true : false,
    speed: 500,
    slidesToShow: 5.4,
    slidesToScroll: 2,
    // variableWidth: true,
    arrows: true,
    swipeToSlide: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1840,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1640,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          swipeToSlide: true,
          draggable: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings} className={css.listingsDesktopView}>
        {listings?.length > 0 &&
          listings?.map((list, index) => {
            return (
              <div key={`${list.id}_${index}`} style={{ width: 306 }}>
                <ListingCard
                  className={css.listingCard}
                  key={list.id.uuid}
                  listing={list}
                  renderSizes={renderSizes}
                  setActiveListing={() => dispatch(setActiveListing(list.id))}
                />
              </div>
            );
          })}
      </Slider>
      <div className={css.listingsMobileView}>
        {listings?.length > 0 &&
          listings?.map((list, index) => {
            return (
              <div key={`${list.id}_${index}`} style={{ width: 306 }}>
                <ListingCard
                  className={css.listingCard}
                  key={list.id.uuid}
                  listing={list}
                  renderSizes={renderSizes}
                  setActiveListing={() => dispatch(setActiveListing(list.id))}
                />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default GetItBeforeGoneListings;
