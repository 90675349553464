import React, { useState, useEffect } from 'react';
import css from './SectionBuilder.module.css';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const GamesSection = props => {
  const { gamesSection = {} } = props;
  const [loading, setLoading] = useState(true); // Add loading state
  const { blocks = [], title = {} } = gamesSection;

  useEffect(() => {
    // Simulate an async call
    if (blocks.length > 0) {
      setLoading(false); // Set loading to false once blocks are available
    }
  }, [blocks]);

  return (
    <div className={classNames(css.sectionTrending, css.gamesContainer)}>
      <div className={css.sectionHeading} style={{ padding: 0 }}>
        <h2 className={css.sectionTitle}>{title.content || 'Arcade'}</h2>
      </div>
      <div className={css.gamesCardWrapper}>
        {loading ? ( // Check if loading
          <p>Loading...</p>
        ) : blocks.length === 0 ? (
          <p>No content available</p>
        ) : (
          blocks.map((block, index) => {
            const { media = {}, title = {} } = block;
            const image = media?.image?.attributes?.variants?.['original400'];
            return (
              <a href="/rewards" className={css.gameCard} key={index}>
                <h2 className={css.title}>{title.content || 'Default Game Title'}</h2>
                {image ? (
                  <img key={`${index}-${image.name}`} src={image.url} alt={image.name} />
                ) : (
                  <div>No Image Available</div>
                )}
                <button>Play Now</button>
              </a>
            );
          })
        )}
      </div>
    </div>
  );
};

export default GamesSection;
